<template>
  <v-card class="my-2">
    <v-card-text>Aaa</v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ProjectInfo",
};
</script>

<style></style>
